import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Search from "./components/Search";
import Footer from "./components/Footer";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Loader from "./components/Loader";
import PageNotFound from "./components/PageNotFound";
import Brand from "./components/Brand";
import Contact from "./components/Contact";

function App() {
    return (
        <BrowserRouter>
            <Loader/>
            <Navbar/>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path={"/brand/:id"} element={<Brand/>}/>
                    <Route path="/search" element={<Search/>}/>
                    <Route path="/privacy-policy" element={<Privacy/>}/>
                    <Route path="/terms-of-use" element={<Terms/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/404" element={<PageNotFound />} />
                </Routes>
            </div>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
