import React from 'react';
import LoadGif from '../style/load.gif';
import '../style/Slider.css';
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper/modules";
const Slider = ({ coupons }) => {
    const imageRef = React.useRef(null);

    const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = LoadGif;
    };

    function calculateRemainingDays(startDateStr, endDateStr) {
        const now = new Date();
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        if (now < startDate) {
            return "Event has not started";
        }
        const timeLeft = endDate - now;
        if (timeLeft < 0) {
            return "Event has ended";
        }
        const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        return `${daysLeft} days left`;
    }

    return (
        <div className="Carousel-Container">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={30}
                slidesPerView={3}
                navigation
                autoplay={{ delay: 2500 }}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 40
                    },
                }}
            >
                {coupons.slice(0,10).map((result, index) => (
                    <SwiperSlide key={index}>
                        <a className='Coupon-Container' href={result.redirect_link} target="_blank" rel="noopener noreferrer">
                            <div className="Image-Container">
                                <img
                                    ref={imageRef}
                                    src={result.logo}
                                    alt={result.merchant_name}
                                    onError={handleImageError}
                                    className="lazy-load"
                                />
                            </div>
                            <div className="Coupon-Desc">
                                <p className="Coupon-Cat">{result.category.split("|")[0]}</p>
                                <p className="Coupon-Title">{result.title}</p>
                                <p>{result.merchant_name}</p>
                                <p className="Coupon-Time">{calculateRemainingDays(result.start_date, result.end_date)}</p>
                            </div>
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
);
};

export default Slider;