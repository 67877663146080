import React from 'react';
import LoadGif from '../style/load.gif';
import '../style/Coupon.css';

const Coupon = ({ coupon }) => {
    const imageRef = React.useRef(null);

    const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = LoadGif;
    };

    function calculateRemainingDays(startDateStr, endDateStr) {
        const now = new Date();
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        if (now < startDate) {
            return "Event has not started";
        }
        const timeLeft = endDate - now;
        if (timeLeft < 0) {
            return "Event has ended";
        }
        const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        return `${daysLeft} days left`;
    }

    return (
        <a className='Coupon-Container-Home' href={coupon.redirect_link} target="_blank" rel="noopener noreferrer">
            <div className="Image-Container-Home">
                <img
                    ref={imageRef}
                    src={coupon.logo}
                    alt={coupon.merchant_name}
                    onError={handleImageError}
                    className="lazy-load"
                />
            </div>
            <div className="Coupon-Desc-Home">
                <p className="Coupon-Cat-Home">{coupon.category.split("|")[0]}</p>
                <p className="Coupon-Title-Home">{coupon.title}</p>
                <p>{coupon.merchant_name}</p>
                <p className="Coupon-Time-Home">{calculateRemainingDays(coupon.start_date, coupon.end_date)}</p>
            </div>
            <div className="Coupon-CTA-Container">
                <button>REDEEM AT STORE</button>
            </div>
        </a>
    );
};

export default Coupon;