import { atom } from "recoil"

export const isLoading = atom({
    key: 'load',
    default: false
});

export const countryState = atom({
    key: 'county',
    default: 'us'
});
