import React, {useEffect, useState} from 'react';
import '../style/Brand.css';
import Coupon from "./Coupon";
import { useLocation } from 'react-router-dom';


const Brand = () => {
    const location = useLocation();

    const state = location.state;
    const [coupons, setCoupons] = useState(state.coupons);

    const getBrandCoupons = (brand) => {
        const brandCoupons = []
        coupons.forEach((result) => {
            if (result.merchant_name === brand) {
                brandCoupons.push(result);
            }
        });
        return brandCoupons;
    }

    useEffect(() => {
        const brandCoupons = getBrandCoupons(state.brand);
        setCoupons(brandCoupons);

    }, []);

    return (
        <div className="Home-Container">
            <h1>{state.brand} Coupons</h1>
            <div className="Brand-Coupons-Container">
                {coupons.map((result, index) => (
                    <Coupon key={index} coupon={result}/>
                ))}
            </div>
        </div>
    );
};

export default Brand;
