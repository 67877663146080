import React from 'react';
import '../style/privacy-terms.css';

const Privacy = () => {
    return (
        <div className="Privacy-Container">
            <h1>Privacy Policy</h1>
            <div className="entry-section">
                <p>This Privacy Policy explains how we use the personal information that&nbsp;PromoCoder &nbsp;collects
                    or generates both in relation to this website and our products and services.</p>


                <p>This Privacy Policy applies to your use of PromoCoder Website and/or PromoCoder Products (as defined
                    below). If you
                    do not wish to bound by this PP and/or the TOU, exit the PromoCoder Website, Publisher Asset (as
                    defined below)
                    and/or PromoCoder Products terminate your usage (or attempt to use) of the Product(s).</p>


                <p>This Privacy Policy is current as of the Effective Date set forth above. We may change this Privacy
                    Policy
                    from time to time, so please be sure to check back periodically. We will post any changes to this
                    Privacy
                    Policy on PromoCoder Website.</p>


                <p>The list below sets out what is covered in this Privacy Policy and you can click on the headings
                    below to go
                    to a specific section.</p>


                <p>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BACKGROUND</p>


                <p>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE PRODUCTS AND SERVICES WE PROVIDE</p>


                <p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE TYPES OF PERSONAL DATA WE COLLECT</p>


                <p>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW WE USE USER’S INFORMATION</p>


                <p>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DISCLOSURE OF USER’S INFORMATION TO THIRD PARTIES</p>


                <p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INTERNATIONAL&nbsp;TRANSFERS OF PERSONAL DATA</p>


                <p>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW WE SAFEGUARD USER’S INFORMATION</p>


                <p>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW LONG WE KEEP USER’S PERSONAL DATA</p>


                <p>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;USER’S RIGHTS</p>


                <p>10.&nbsp;&nbsp;DESIGNATED REPRESENTATIVE</p>


                <p>11.&nbsp;&nbsp;QUESTIONS AND CONCERNS</p>


                <p>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BACKGROUND</p>


                <p>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PromoCoder&nbsp;with
                    its
                    registered office at&nbsp;8 The Green, Ste A, Dover, DE 19901&nbsp;and other companies in
                    the&nbsp;PromoCoder&nbsp;group&nbsp;collect
                    and use certain Personal Data&nbsp;(as we defined below). PromoCoder&nbsp;is responsible for ensuring
                    that it
                    uses that Personal Data in compliance with data protection laws.</p>


                <p>1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;At&nbsp;PromoCoder&nbsp;we
                    respect the privacy of our clients and we are committed to keeping all your Personal Data secure.
                    This
                    Privacy Policy governs the handling of Personal Data by&nbsp;PromoCoder&nbsp;in the course of carrying
                    on
                    commercial activities.</p>


                <p>1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We use
                    the&nbsp;following&nbsp;definitions
                    in this Privacy Policy:</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Customer” a publisher who
                    own/controls/operates
                    Internet assets (such as Websites) (“Publisher Assets”) who incorporates PromoCoder Products in his
                    assets
                    (“Publisher”) or an&nbsp;advertiser&nbsp;who provides us with content to be served through PromoCoder
                    Products
                    and/or Publishers assets (“Advertiser”), or with whom PromoCoder a contractual relationship</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Personal Data”&nbsp;means any data
                    which
                    relates to a living individual who can be identified from that data or from that data and other
                    information
                    which is in the possession of, or is likely to come into the possession of,&nbsp;PromoCoder&nbsp;(or
                    its
                    representatives or service providers). In addition to factual information, it includes any
                    expression of
                    opinion about an individual and any indication of the intentions of&nbsp;PromoCoder&nbsp;or any other
                    person in
                    respect of an individual.</p>


                <p>(C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“PromoCoder”,&nbsp;“we or
                    “us”&nbsp;means&nbsp;PromoCoder&nbsp;and
                    other companies in the&nbsp;PromoCoder&nbsp;group.</p>


                <p>(D)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“PromoCoder Website”&nbsp;www.promo-coder.com or
                    any other
                    PromoCoder website displaying this Privacy Policy.</p>


                <p>(E)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“User” a person making any use of
                    PromoCoder
                    Website and/or PromoCoder Products.</p>


                <p>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE PRODUCTS AND
                    SERVICES WE
                    PROVIDE</p>


                <p>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This
                    Privacy&nbsp;Policy&nbsp;concerns
                    the following categories of information that we collect about the User when providing the following
                    products
                    and services:</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information we&nbsp;receive&nbsp;through our
                    websites&nbsp;(“PromoCoder&nbsp;Websites”);</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information&nbsp;we&nbsp;receive
                    through our
                    products&nbsp;(“PromoCoder&nbsp;Products”);</p>


                <p>(C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information&nbsp;we&nbsp;receive
                    through our
                    support or cloud-based services&nbsp;(“PromoCoder&nbsp;Services”).</p>


                <p>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Personal&nbsp;Data&nbsp;may
                    be collected from (a) PromoCoder Websites – for example with respect to personnel of our Customers
                    engaging us
                    through the PromoCoder Website; (b) PromoCoder Products – for example when a User interact with Publisher
                    assets.</p>


                <p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE TYPES OF PERSONAL
                    DATA WE
                    COLLECT.&nbsp;Many of the services offered by&nbsp;PromoCoder&nbsp;require us to obtain Personal Data
                    about a
                    User in order to perform the services we have been engaged to provide. In relation to each of the
                    services
                    described at&nbsp;Section&nbsp;2.1 above, we will collect and process the following Personal Data
                    about the
                    Users&nbsp;–</p>


                <p>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information
                    that a
                    User provide to&nbsp;PromoCoder. This includes information about Users that they provide to us. The
                    nature of
                    the services Users are requesting will determine the kind of&nbsp;Personal&nbsp;Data we might ask
                    for,
                    though such information may include (by way of a non-exhaustive list):</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;basic Personal Data (such as first name;
                    family
                    name; position in the company; company&nbsp;name; company email address; business phone number;
                    business
                    address; city; postcode; country);</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any information that a User choose to
                    share on&nbsp;PromoCoder&nbsp;internet
                    forums which may be considered Personal Data. (Please note that&nbsp;PromoCoder&nbsp;does not collate
                    information included on&nbsp;PromoCoder&nbsp;internet forums together with Personal Data from a User
                    Center
                    account or profile);</p>


                <p>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information
                    that we
                    collect or generate about Users.&nbsp;This includes (by way of non-exhaustive list):</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A file with Users’ contact history to be
                    used for
                    enquiry purposes so that we may ensure that Users are satisfied with the services which we have
                    provided to
                    them;</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information on the internet usage or
                    browsing
                    habits of Users&nbsp;of Publisher Assets&nbsp;(e.g. what&nbsp;websites&nbsp;or pages were visited by
                    each
                    User, any advertisements viewed by Users and resulting actions such as visits to other websites);
                    and</p>


                <p>(C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The internet browser used, the
                    internet device
                    operating system and device ID, IP address (from which geo location may be extracted to a level of a
                    city)</p>


                <p>3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information we
                    obtain
                    from other sources.</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookies.</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When Users
                    visit&nbsp;PromoCoder&nbsp;Websites,
                    use PromoCoder Products and/or Publisher Assets, cookies are used to collect technical information
                    about the
                    services that they use, and how they use them.</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For more information on the
                    cookies
                    used by&nbsp;PromoCoder&nbsp;please see our Cookie Notice.</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anonymized data.&nbsp;In addition to
                    the
                    categories of Personal Data described above,&nbsp;PromoCoder&nbsp;will also process further anonymized
                    information and data that is not processed by reference to a specific individual.</p>


                <p>3.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information we
                    get
                    from third parties:&nbsp;The information we collect comes mainly directly from Users or as a result
                    of
                    Users’ browsing habits. Sometimes we might collect personal data about Users from other sources,
                    such as
                    publicly available materials or trusted third parties like our marketing and research
                    partners.&nbsp;We use
                    this information to supplement the personal data we already hold about Users, in order to better
                    inform,
                    personalize and improve our services, and to validate the personal data Users provide.</p>


                <p>3.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advertisers.
                    We may
                    also use third parties such as network Advertisers, which are third parties that display
                    advertisements
                    based on Users’ visits to websites they have visited to target advertisements for products and
                    services in
                    which Users might be interested. Third-party ad network providers, Advertisers, sponsors and/or
                    traffic
                    measurement services may also use cookies, JavaScript, web beacons (including clear GIFs), Flash
                    LSOs and
                    other technologies to measure the effectiveness of their ads and to personalize advertising content
                    to
                    Users. These third-party cookies and other technologies are governed by each third party’s specific
                    privacy
                    policy, not this one.</p>


                <p>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW WE USE USERS’
                    INFORMATION</p>


                <p>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users’&nbsp;Personal&nbsp;Data
                    may be stored and processed by us in the following ways and for the following purposes:</p>


                <p>Business operation</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to understand Users needs and interests;</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Through PromoCoder Products, on Publisher
                    Websites, we may provide advertisements to Users based on their recent browsing behaviour on the
                    Publisher
                    Website. For example, if Users visit and browse on certain pages on Website A, on a subsequent
                    online visit
                    to Website A, Users may see content personalized based on their browsing history on Website A. For
                    example,
                    if Users visit a clothing retail site, we may offer them clothing-related ads. In order to target
                    advertisements to Users for products and services in which Users might be interested, we may use
                    cookies,
                    JavaScript, web beacons (including clear GIFs), HTML5 local storage and other technologies. For more
                    information about how we use these technologies, please refer to our&nbsp;<a
                        title="PromoCoder PRIVACY POLICY"
                        href="http://www.promo-coder.com/cookie-policy?gclid=CjwKCAiAoL6eBhA3EiwAXDom5m6ctbaPk28i8rYqv3ctop4Q8iA86vOKaz_yIgAPWWFpGWOKrAjkeBoCbAkQAvD_BwE">Cookie
                        Policy</a>.</p>


                <p>(C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To communicate with Users in order to
                    provide
                    them with services or information about&nbsp;PromoCoder and PromoCoder&nbsp;Products;</p>


                <p>(D)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To allow Users to use and access the
                    functionality
                    provided by the&nbsp;PromoCoder&nbsp;Products&nbsp;and/or PromoCoder Website;</p>


                <p>(E)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To assess Users’ application
                    for&nbsp;PromoCoder&nbsp;Products&nbsp;and/or
                    PromoCoder Website, where applicable;</p>


                <p>(F)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To set up&nbsp;Customers&nbsp;to
                    use&nbsp;PromoCoder&nbsp;Products&nbsp;and/or
                    PromoCoder Website;</p>


                <p>(G)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To set up Users to use the User
                    Centre&nbsp;and/or
                    PromoCoder Website;</p>


                <p>(H)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To personalize the User experience;</p>


                <p>(I)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for the management and
                    administration of our business. For example – (i) to respond to User’s requests and support PromoCoder
                    Costumers or Users needs more efficiently; (ii) to administrate payments;</p>


                <p>(J)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the administration and
                    maintenance
                    of databases storing Personal Data;</p>


                <p>Security purposes</p>


                <p>(K)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To conduct analysis required to detect
                    malicious
                    data and understand how this may affect the User&nbsp;and/or our&nbsp;IT system;</p>


                <p>(L)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For statistical monitoring and
                    analysis of
                    current attacks on devices and systems and for the on-going adaptation of the solutions provided to
                    secure
                    devices and systems against current attacks;</p>


                <p>(M)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To offer User(s) with content PromoCoder believes may
                    interest such User(s), such as targeted advertising content or other commercial information;</p>


                <p>(N)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In order to comply with and in order to
                    assess
                    compliance with applicable laws, rules and regulations, and internal policies and procedures;</p>


                <p>Research and development</p>


                <p>(O)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for ongoing review and improvement of the
                    information provided on&nbsp;PromoCoder&nbsp;Websites to ensure they are User friendly and to prevent
                    any
                    potential disruptions or cyber-attacks;</p>


                <p>(P)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To understand feedback on PromoCoder
                    Products and
                    to help provide more information on the use of those products and services quickly and easily;</p>


                <p>(Q)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For in-depth threat analysis;</p>


                <p>(R)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To improve PromoCoder Services, PromoCoder
                    Products
                    and/or PromoCoder Website and develop new products services and websites</p>


                <p>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;However&nbsp;we
                    use
                    Personal Data we make sure that the usage complies with law and the law allows us and requires us to
                    use
                    Personal Data for a variety of reasons. These include:</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We have obtained Users’ consent;</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We have legal and regulatory
                    obligations that
                    we have to discharge;</p>


                <p>(C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may need to do so in order to
                    establish,
                    exercise or defend our legal rights or for the purpose of legal proceedings;</p>


                <p>(D)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The use of Users’ Personal Data as described
                    is
                    necessary for our legitimate business interests, such as:</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;allowing us to effectively
                    and
                    efficiently manage and administer the operation of our business;</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We need to do so in order to
                    perform
                    our contractual obligations with&nbsp;our customers;</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;maintaining compliance with
                    internal
                    policies and procedures;</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;monitoring the use of our
                    copyrighted materials;</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enabling quick and easy
                    access to
                    information on&nbsp;PromoCoder&nbsp;Products;</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;offering optimal, up-to-date
                    security solutions for mobile devices and IT systems; and</p>


                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;obtaining further knowledge
                    of
                    current threats to network security in order to update our security solutions.</p>


                <p>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We
                    will&nbsp;take&nbsp;steps
                    to ensure that the Personal Data is accessed only by employees of&nbsp;PromoCoder&nbsp;that have a need
                    to do
                    so for the purposes described in this Privacy Policy.</p>


                <p>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DISCLOSURE OF USERS’
                    INFORMATION TO THIRD PARTIES</p>


                <p>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may share
                    Users’
                    Personal Data within the&nbsp;PromoCoder&nbsp;group of companies for the purposes described&nbsp;is
                    Section&nbsp;‎4.1&nbsp;above.</p>


                <p>5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may also
                    share
                    Users’ Personal Data outside of the&nbsp;PromoCoder&nbsp;group for the following purposes:</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With our business partners&nbsp;(such as
                    PromoCoder
                    Customers).&nbsp;For example, this could include our&nbsp;Advertisers whose&nbsp;content
                    Users&nbsp;choose
                    to interact with and Publishers who’s Publisher Assets Users interact with.&nbsp;Personal Data will
                    only be
                    transferred to a business partner who is contractually obliged to comply with appropriate data
                    protection
                    obligations and the relevant privacy and confidentiality legislation;</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With third party agents and
                    contractors for
                    the purposes of providing services to us (for example,&nbsp;PromoCoder’s&nbsp;accountants, professional
                    advisors, IT and communications providers and debt collectors). These third parties will be subject
                    to
                    appropriate data protection obligations and they will only use Users’ Personal Data as described in
                    this
                    Privacy Policy;</p>


                <p>(C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if we sell our business or assets, in
                    which
                    case we may need to disclose Users’ Personal Data to the prospective buyer for due diligence
                    purposes;
                    and</p>


                <p>(D)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to the extent required by law, for example
                    if we are
                    required to disclose Users’ Personal Data in order to comply with any legal obligation (including,
                    without
                    limitation, in order to comply with tax reporting requirements and disclosures to regulators), or to
                    establish, exercise or defend its legal rights;</p>


                <p>(E)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If we are acquired by a third party,
                    in which
                    case the Personal Data held by us about Users will be disclosed to the third party buyer.</p>


                <p>(H)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3rd Party Data Co-Processors – Data
                    may be
                    shared with services utilized as co-processors for services related to your account, including
                    payment
                    processors or live chat support. Intercom is our data processing partner.</p>


                <p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INTERNATIONAL
                    TRANSFERS OF
                    PERSONAL DATA</p>


                <p>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PromoCoder&nbsp;is
                    a
                    global business. Our customers and our operations are spread around the world. As a result we
                    collect and
                    transfer Personal Data on a global basis. That means that we may&nbsp;transfer&nbsp;Users’ Personal
                    Data&nbsp;to
                    locations outside of Users’ country.</p>


                <p>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Where we
                    transfer
                    Users’ Personal Data to another country outside the EEA, we will ensure that it is protected and
                    transferred
                    in a manner consistent with legal requirements. In relation to data being transferred outside of
                    Europe, for
                    example, this may be done in one of the following ways:</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the country that we send the data to might
                    be
                    approved by the European Commission as offering an adequate level of protection for Personal Data</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the recipient might have signed up to
                    a
                    contract based on “model contractual clauses” approved by the European Commission, obliging them to
                    protect
                    your Personal Data;</p>


                <p>(C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Where the recipient is located in the
                    US, it
                    might be a certified member of the EU-US Privacy Shield scheme; or</p>


                <p>(D)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In other circumstances the law may permit we
                    to
                    otherwise transfer Users ‘Personal Data outside Europe.</p>


                <p>6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users can
                    obtain more
                    details of the protection given to their Personal Data when it is transferred outside Europe
                    (including a
                    copy of the standard data protection clauses which we&nbsp;have&nbsp;entered into with recipients of
                    Users’
                    Personal Data) by contacting us as described in&nbsp;Section&nbsp;‎12&nbsp;below.</p>


                <p>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW WE SAFEGUARD
                    USERS’
                    INFORMATION</p>


                <p>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We have
                    extensive
                    controls in place to maintain the security of our information and information systems. Client files
                    are
                    protected with safeguards according to the sensitivity of the relevant information. Appropriate
                    controls
                    (such as restricted access) are placed on our computer systems. Physical access to areas where
                    Personal Data
                    is gathered, processed or stored is limited to authorised employees.</p>


                <p>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As a condition
                    of
                    employment,&nbsp;PromoCoder&nbsp;employees are required to follow all applicable laws and regulations,
                    including in relation to data protection law. Access to sensitive Personal Data is limited to those
                    employees who need to it to perform their roles. Unauthorised use or disclosure of confidential
                    client
                    information by an&nbsp;PromoCoder&nbsp;employee is prohibited and may result in disciplinary
                    measures.</p>


                <p>7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When Users
                    contact an&nbsp;PromoCoder&nbsp;employee
                    about their file, they may be asked for some&nbsp;Personal Data. This type of safeguard is designed
                    to
                    ensure that only a User, or someone authorised by the User, has access to their file.</p>


                <p>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOW LONG WE KEEP USER
                    PERSONAL DATA.&nbsp;How long we will hold Users’ Personal Data for will vary and will be determined
                    by the
                    following criteria:</p>


                <p>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The purpose
                    for which
                    we are using it –&nbsp;PromoCoder&nbsp;will need to keep the data for as long as is necessary for that
                    purpose;
                    and</p>


                <p>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;legal
                    obligations –
                    laws or regulation may set a minimum period for which we have to keep Users’ Personal Data.</p>


                <p>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;USERS RIGHTS</p>


                <p>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In all the
                    above cases
                    in which we collect, use or store Users’ Personal Data, Users may have
                    the&nbsp;following&nbsp;rights and,
                    in most cases, Users can exercise them free of charge. These rights include:</p>


                <p>(A)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the right to obtain information regarding
                    the
                    processing of&nbsp;Users’&nbsp;Personal Data and access to the Personal Data which we hold about
                    them;</p>


                <p>(B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The right to withdraw Users’ consent
                    to the
                    processing of Users’ Personal Data at any time. Please note, however, that we may still be entitled
                    to
                    process Users’ Personal Data if we have another legitimate reason for doing so. For example, we may
                    need to
                    retain Personal Data to comply with a legal obligation;</p>


                <p>(C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The right to request that we rectify
                    Users’
                    Personal Data if it is inaccurate or incomplete;</p>


                <p>(D)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In some circumstances, the right to receive
                    some
                    Personal Data in a structured, commonly used and machine-readable format and/or request that we
                    transmit
                    those data to a third party where this is technically feasible. Please note that this right only
                    applies to
                    Personal Data which Users have provided directly to&nbsp;PromoCoder;</p>


                <p>(E)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The right to request that we erase
                    Users’
                    Personal Data in certain circumstances. Please note that there may be circumstances where Users ask
                    us to
                    erase their Personal Data but we are legally entitled to retain it;</p>


                <p>(F)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The right to object to, or request
                    that we
                    restrict, our processing of Users’ Personal Data in certain circumstances. Again, there may be
                    circumstances
                    where Users object to, or ask us to restrict, our processing of Users’ &nbsp;Personal Data but we
                    are
                    legally entitled to refuse that request; and</p>


                <p>(G)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The right to lodge a complaint with the
                    relevant
                    data protection regulator if Users think that any of their rights have been infringed by us.</p>


                <p>9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users&nbsp;can&nbsp;exercise&nbsp;their
                    rights by contacting us using the details listed in&nbsp;Section&nbsp;‎12&nbsp;below.</p>


                <p>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THIRD PARTY CONTENT.</p>


                <p>10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Certain content is made
                    available
                    through the PromoCoder Products and/or PromoCoder Website is of third&nbsp;parties. Such content (which
                    may
                    include targeted advertising content) is made available to Users by link to such third parties sites
                    or
                    products, ads displayed on their Internet device, or otherwise. PromoCoder does not control the content
                    made
                    available by such third parties or the practices employed by these third parties. PromoCoder is not and
                    will
                    not be liable or responsible for any content provided by such third parties or the practices they
                    employ.
                    Users are advised to check the privacy policies and terms of use of any third party before
                    interacting or
                    making any use of such third party’s software, site or product.</p>


                <p>10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PromoCoder Products and/or PromoCoder
                    Website
                    may also include social media features and scripts, such as the Facebook Like button and widgets, or
                    interactive mini-programs that run on our site. These features,&nbsp;if Users decide to use them,
                    may
                    collect their IP address, which page they are visiting on our site, and may set a cookie to enable
                    the
                    feature to function properly. Social media features and widgets are either hosted by a third party
                    or hosted
                    directly on our site.&nbsp;Users’&nbsp;interactions with these features are governed by the privacy
                    policy
                    of the entity providing it.</p>


                <p>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CHILDREN’S PRIVACY</p>


                <p>The PromoCoder&nbsp;Products&nbsp;and/or PromoCoder Website content is intended for adults and we will not
                    knowingly collect personal information from children under 16 years of age. If we become aware that
                    we have
                    unknowingly collected Personal Information from a child under the age of 16, we will make reasonable
                    efforts
                    to delete such&nbsp;information&nbsp;from our records.&nbsp; If a User is the parent or legal
                    guardian of a
                    child under age 16 who they believe has submitted personal information to the PromoCoder Products
                    and/or
                    PromoCoder Website, They should contact us immediately.</p>


                <p>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QUESTIONS AND CONCERNS.&nbsp;If you
                    have any
                    questions or concerns about&nbsp;PromoCoder’s&nbsp;handling of your Personal Data, or about this
                    Policy, please
                    contact our Privacy Officer using the following contact information:</p>


                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em>Address:</em>&nbsp;Raoul
                    Wallenberg 18, Tel Aviv, Israel</p>


                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em>Email
                    Address:</em>&nbsp;info@promo-coder.com
                </p>


                <p>We are usually able to resolve privacy questions or concerns promptly and effectively. If you are not
                    satisfied with the response you receive from our Privacy Officer, you may escalate concerns to the
                    applicable privacy regulator in your jurisdiction. Upon request,&nbsp;PromoCoders’&nbsp;Privacy Officer
                    will
                    provide you with the contact information for that regulator.</p>
            </div>
        </div>
    );
};

export default Privacy;
